<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00', '23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'searchForm')" @goodsSelectEvent="goodsDataEvent($event, 'searchForm')"></goods-change>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">成本调整单列表</p>
        <template>
          <div class="tableTopBtn">
            <el-button @click="add" type="primary" class="el-button--mini" v-if="addButton == '1'"><i class="el-icon-plus"></i>添加调整单</el-button>
            <el-button size="mini" type="danger" @click="handleDel()" :disabled="ids.length == 0" v-if="delButton == '1'" class="el-icon-delete">批量删除</el-button>
          </div>
          <el-table ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" tooltip-effect="dark" style="width: 100%" v-loading="loading">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table ref="multipleTable2" :data="props.row.costAdjustmentChildOrderList">
                  <el-table-column width="100"> </el-table-column>
                  <el-table-column prop="goodsName" label="产品名称"> </el-table-column>
                  <el-table-column prop="standard" label="产品规格"> </el-table-column>
                  <el-table-column prop="price" label="调整价格"> </el-table-column>
                  <el-table-column prop="created" label="创建时间"> </el-table-column>
                  <el-table-column prop="remark" label="备注"> </el-table-column>
                  <el-table-column align="center" width="360" label="操作">
                    <template>
                      <el-button size="mini" type="primary" @click="edit(props.row.id)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            
            <el-table-column prop="warehouseName" label="仓库名称"> </el-table-column>
            <el-table-column prop="price" label="调整金额"> </el-table-column>
            <el-table-column prop="createUser" label="创建人"> </el-table-column>
            <el-table-column prop="orderTime" label="单据日期"></el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column align="center" width="360" label="操作">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="edit(scope.row.id)" v-if="updateButton == '1'" class="el-icon-edit">编辑</el-button>
                <el-button size="mini" type="primary" @click="details(scope.row.id)" v-if="updateButton == '1'">详情</el-button>
                <el-button size="mini" type="danger" @click="handleDel(scope.row.id)" v-if="delButton == '1'" class="el-icon-delete">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.current" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    
  </div>
</template>

<script>
import goodsChange from "../../components/goodsChange.vue";
export default {
  components: {
    goodsChange
  },
  data() {
    return {
      time:[],
      tableData: [],
      loading: true,
      //分页数据
      pagesData: {
        total: 0,
        currentPage: 1,
        currentRows: 10,
        rows: [10, 20, 30, 40],
      },
      
      //搜索
      searchForm: {
        name: "",
      },
      //form表单数据
      formData: {
        name: "",
        principal: "",
        phone: "",
        address: "",
        remark: "",
      },
      multipleTable: [],
      multipleSelection2:[],
      ids: [],
      id: "",
      idStr: "",
      searchButton: "",
      updateButton: "",
      addButton: "",
      delButton: "",
      
    };
  },

  created() {
    this.listEvent();
    
    //获取页面按钮权限
    var privilege = JSON.parse(sessionStorage.getItem("authority"));
    privilege.forEach((item) => {
      if (item.authority == "cost_adjustment_order_update") {
        this.updateButton = "1";
      } else if (item.authority == "cost_adjustment_order_query") {
        this.searchButton = "1";
      } else if (item.authority == "cost_adjustment_order_add") {
        this.addButton = "1";
      } else if (item.authority == "cost_adjustment_order_delete") {
        this.delButton = "1";
      }
    });
  },
  activated(){
    let params = this.$route.params
    if(params.type == 'update'){
      this.listEvent();
    }
  },
  // watch: {
  //   // 如果路由有变化，会再次执行该方法
  //   $route: "listEvent",
  //   goodsListShow: {
  //     handler(n) {
  //       if (!n) {
  //         this.goodsPagesData.currentPage = 1
  //       }
  //     }
  //   },
  //   liuShuiShow: {
  //     handler(n) {
  //       if (!n) {
  //         this.liushuipagesData.currentPage = 1
  //       }
  //     }
  //   },
  // },
  methods: {
    getPopupGoods(data, formName) {
      this.$set(this[formName], 'goodsId', data.id)
    },
    goodsDataEvent(data, formName) {
      let goodsIds = [];
      let names = []
      data.forEach(item => {
        goodsIds.push(item.id)
        names.push(item.goodsName)
      })
      this.$refs.goodsChange.goodsName = names.join(',')
      this.$set(this[formName], 'goodsId', goodsIds.join(','))
    },
    changeTime(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      _this.loading = true;
      this.searchForm.current = this.pagesData.currentPage
      this.searchForm.size = this.pagesData.currentRows
      this.$axios
        .get(_this.$axios.defaults.basePath + "/costAdjustmentOrder", {
          params: _this.searchForm,
        })
        .then(function (res) {
          console.log(res)
          if (res.data.errcode == 0) {
            _this.loading = false;
            _this.tableData = res.data.data.records;
            _this.pagesData.total = res.data.data.total;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    //分页--每页条数切换
    handleSizeChange(val, flag) {
      this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      this.pagesData.currentPage = val;
      this.listEvent();
    },

    //搜索操作
    searchSubmit() {
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach((key) => {
        this.searchForm[key] = "";
      });
      this.pagesData.currentPage = 1;
      this.pagesData.currentRows = 10;
      this.listEvent();
    },

    add() {
      this.$router.push({ name: 'costChangeAdd', params: { type: 'add' } })
    },
    //编辑
    edit(row) {
      this.$router.push({ name: 'costChangeAdd', params: { type: 'edit', id: row } })
    },
    // 详情
    details(id){
      this.$router.push({ name: 'costChangeAdd', params: { type: 'details', id: id } })
    },
    //勾选列表触发事件
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      _this.multipleSelection = val;
      _this.multipleSelection.forEach((item) => {
        _this.ids.push(item.id);
      });
    },

    //删除操作
    handleDel(id) {
      var _this = this;
      let idStr = ''
      if(id){
        idStr = id
      }else if (_this.ids.length == 0) {
        _this.$message.error("请选择要删除的内容");
        return false;
      } else {
        idStr = _this.ids.join(",")
      }
      _this
        .$confirm("此操作将永久删除该仓库, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          var _this = this;
          _this.$axios
            .get(_this.$axios.defaults.basePath + "/costAdjustmentOrder/delete", {
              params: {
                ids: idStr,
              },
            })
            .then(function (res) {
              if (res.data.errcode == 0) {
                _this.$message({
                  message: "删除成功",
                  type: "success",
                });
                _this.listEvent();
              }
            });
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style scoped>
.tip {
  color: sandybrown;
  line-height: 30px;
  margin-top: 15px;
}
.grid-content {
  height: 40px;
  display: flex;
  align-items: center;
}
</style>

<style lang="scss" scoped>
::v-deep .postForm .el-input {
  width: auto !important;
}
</style>
